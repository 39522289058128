import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

import SuperTokens, {
  getSuperTokensRoutesForReactRouterDom,
} from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import { EmailPasswordAuth } from "supertokens-auth-react/recipe/emailpassword";
import "./App.css";
import Home from "./home";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Navbar } from "react-bootstrap";
import PDF from "./pdf";
import Calculator from "./calculator";
import OnBoardingDemo from "./onboarding/demo/onboarding";
import OnBoardingDemo2 from "./onboarding/demo2/onboarding2";
import OnBoardingDemo3 from "./onboarding/demo3/onboarding";
import OnBoardingDemo4 from "./onboarding/demo4/onboarding4";
import StartOnboarding from "./onboarding/startOnboarding";
import ManagementAgreement from "./documents/managementAgreement";
import SignBOS from "./documents/bos";
import AmbassadorContent from "./ambassadorPortal/ambassadorContent";
import PlaceOrder from "./placeOrder/placeOrder";
import PlaceOrderExistingTrees from "./placeOrder/existingTrees";
import PlaceOrderNewTrees from "./placeOrder/newTrees";
import LogoutComponent from "./utilities/loggingOut";

SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.io/docs/emailpassword/appinfo
    appName: "Las Azucenas Impact Investments",
    apiDomain: process.env.REACT_APP_API,
    websiteDomain: process.env.REACT_APP_WEB,
  },
  recipeList: [
    EmailPassword.init({
      palette: {
        primary: "seagreen",
        textTitle: "seagreen",
      },
    }),
    Session.init(),
  ],
});

function App() {
  const [showHeader, setShowHeader] = useState(false);
  const revealSignUp = () => {
    alert("test");
    const test = document.querySelector("[data-supertokens='secondaryText']");
  };

  return (
    <div className="App">
      <BrowserRouter>
        {showHeader ? (
          <Navbar variant="light">
            <Container>
              <Navbar.Brand style={{ color: "seagreen", fontWeight: "600" }}>
                {" "}
                <img src="/lasazucenas.png" height="50px" /> Las Azucenas
              </Navbar.Brand>
            </Container>
          </Navbar>
        ) : (
          ""
        )}
        <Switch>
          {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"))}
          <Route exact path="/place-order">
            <PlaceOrder />
          </Route>
          <Route path="/place-order/new-trees">
            <PlaceOrderNewTrees />
          </Route>
          <Route path="/place-order/existing-inventory">
            <PlaceOrderExistingTrees />
          </Route>
          <Route path="/pdf">
            <SessionAuth>
              <PDF />
            </SessionAuth>
          </Route>
          <Route path="/calculator">
            <Calculator />
          </Route>
          <Route path="/onboarding/:onboarding_id">
            <StartOnboarding />
          </Route>
          <Route path="/management-agreement/:management_id">
            <ManagementAgreement />
          </Route>
          <Route path="/bos/:document_id">
            <SignBOS />
          </Route>
          <Route path="/demo-onboarding">
            <OnBoardingDemo />
          </Route>
          <Route path="/demo-onboarding2">
            <OnBoardingDemo2 />
          </Route>
          <Route path="/demo-onboarding3">
            <OnBoardingDemo3 />
          </Route>
          <Route path="/payment-instructions">
            <OnBoardingDemo4 />
          </Route>
          <Route path="/ambassador-portal">
            <AmbassadorContent />
          </Route>
          {/*This renders the login UI on the /auth route*/}
          <Route path="/logout">
            <LogoutComponent />
          </Route>
          <Route path="/">
            <SessionAuth>
              <Home setShowHeader={setShowHeader} />
            </SessionAuth>
          </Route>

          {/* {...} */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
