import axios from "axios";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function OnboardingCenter() {
  const [data, setData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await axios
      .get(process.env.REACT_APP_API + "/admin/onboarding/all")
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      });
  };
  const copyToClipboard = (id) => {
    const link = `https://azucenas.net/onboarding/${id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div className="admin-dash-content">
      <h2>Onboarding</h2>
      <hr />
      <Button variant="success" onClick={() => history.push("/new-client")}>
        Create New Onboarding
      </Button>
      <hr />
      <Col>
        {data.map((item, index) => (
          <Card style={{ marginBottom: "1em" }}>
            <Card.Header>
              {item.name} | {item.email}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Button
                    variant="warning"
                    onClick={() => copyToClipboard(item.onboarding_id)}
                  >
                    Copy Onboarding Link
                  </Button>
                  <br />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ListGroup>
                    <ListGroupItem>
                      <Button
                        onClick={() =>
                          history.push(
                            "/document/" + item.management_id + "/mgmt"
                          )
                        }
                      >
                        Management Agreement
                      </Button>
                    </ListGroupItem>
                    <ListGroupItem>
                      (Client){" "}
                      {item.mgmt_client_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>
                    <ListGroupItem>
                      (Admin){" "}
                      {item.mgmt_admin_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup>
                    <ListGroupItem>
                      <Button
                        onClick={() =>
                          history.push("/document/" + item.bos_id + "/bos")
                        }
                      >
                        Bill of Sale
                      </Button>
                    </ListGroupItem>

                    <ListGroupItem>
                      (Client){" "}
                      {item.bos_client_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>

                    <ListGroupItem>
                      (Admin){" "}
                      {item.bos_admin_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Col>
    </div>
  );
}
