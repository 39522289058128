import React, { useEffect } from 'react';
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
const LogoutComponent = () => {
    
  useEffect(() => {
    const timer = setTimeout(() => {
      logout();
    }, 1500);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const logout = async () => {
    await signOut();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    window.location = '/'
    return false;
  };

  return <div>Logging out...</div>;
};

export default LogoutComponent;
