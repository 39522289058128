import axios from 'axios';
import React, { useState } from 'react';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const CreateNewBlankUser = () => {
    const history = useHistory()
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: ''
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // First Name Validation
        if (!formData.first_name.trim()) {
            formErrors.first_name = 'First name is required';
            isValid = false;
        }

        // Last Name Validation
        if (!formData.last_name.trim()) {
            formErrors.last_name = 'Last name is required';
            isValid = false;
        }

        // Email Validation
        if (!formData.email.trim()) {
            formErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Email is invalid';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(false);

        if (validateForm()) {
            try {
                console.log('Submitting Data:', formData);
                await axios.post(process.env.REACT_APP_API + '/admin/create-blank-user', formData).then(res => {
                    setSubmitted(true);
                    if (res.data.owner_id) {
                        history.push('/owner/' + res.data.owner_id)
                    }

                });
            } catch (err) {
                console.log(err)
                alert('Unable to create new user.')
            }

            // API call simulation

        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'email' ? value.toLowerCase() : value,
        });
    };

    return (
        <Card className="m-4">
            <Card.Header>Create New User</Card.Header>
            <Card.Body>
                <Alert variant='info'>
                    Only use this to create a new account with no trees and no documents.
                </Alert>
                <Form onSubmit={handleSubmit} className="p-4">
                    <Form.Group controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            isInvalid={!!errors.first_name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.first_name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formLastName" className="mt-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            isInvalid={!!errors.last_name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.last_name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail" className="mt-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {submitted && (
                        <Alert variant="success" className="mt-3">
                            Form submitted successfully!
                        </Alert>
                    )}

                    <Button variant="success" type="submit" className="mt-4">
                        Submit
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default CreateNewBlankUser;
